<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    :color="
      barColor !== 'rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.7)'
        ? 'white'
        : 'grey darken-1'
    "
    dark
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    :src="barImage"
    mobile-breakpoint="2600"
    app
    fixed
    mini-variant-width="80"
    width="260"
    v-bind="$attrs"
    class="drawer"
  >
    <template v-slot:img="props">
      <v-img
        :gradient="`to bottom, ${barColor}`"
        v-bind="props"
      />
    </template>
    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title
          class="text-uppercase font-weight-regular text-h3 text-center"
        >
          <v-list-item-content>
            <template>
              <div
                class="d-flex"
                @click.native="redirectToDash()"
              >
                <v-img
                  height="60"
                  width="60"
                  contain
                  src="/img/logo.svg"
                  @click="redirectToDash()"
                />
              </div>
            </template>
          </v-list-item-content>
          <!-- <span class="logo-normal"><img src="/img/logo.svg"></span>
            <span class="logo-mini">{{ $t('ct') }}</span> -->
          <!-- <span class="logo-normal">{{ $t('tim') }}</span> -->
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-divider class="mb-1" />

    <v-list
      dense
      nav
      class="top-content"
    >
      <base-item-group :item="profile" />
    </v-list>

    <v-divider class="mb-2" />

    <v-list
      expand
      nav
    >
      <!--------------PRODUCT MENU BAR----------------------->

    <template>
       <v-list-group
       v-model="menuClosed"
       class="product-menu"
       >
       <template v-slot:activator>
        <v-list-item class="main-menu-text">
          <v-list-item-content>
            <v-list-item-title>
              {{$t('menu.products')}}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
        <v-list-item
          v-for="(item, index) in menuCountryBased[currentCountry]"
          :key="index"
          :to="item.to"
        >
          <v-list-item-icon
            v-if="item.icon"
            :id="'icon-container' + index + currentCountryComputed"
            item.icon
          >
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-title
            :id="'title-link' + index"
          >
            {{ $t(`menu.${toCamelCase(item.title)}`) }}
          </v-list-item-title>
        </v-list-item>
        </v-list-group>
      </template>
      <!--------------PRODUCT MENU BAR----------------------->
      <template>
       <v-list-item>
          <v-list-item-content
          class="pointer-style"
          @click="navigateToUrl()"
          >
            <v-list-item-title>
              {{ $t(`menu.insights`) }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      <template>
        <v-list class="aboutus-style">
          <base-item-group :item="aboutUs" />
        </v-list>
        <v-list-item>
          <v-list-item-content
            @click="redirectToContactUs()"
            class="pointer-style"
          >
            <v-list-item-title>
              {{ $t(`menu.contactUs`) }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content
            @click="redirectToDownloadApp()"
          >
            <v-list-item-title
            class="download-app"
            :title="$t('downloadOurApp')"
            >
              {{ $t(`menu.downloadApp`) }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div class="pl-2">
        <v-menu
          v-model="countryMenuValue"
          close-on-click
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="ml-0 pl-0 btn opaque mt-md-1"
              min-width="0"
              text
              v-bind="attrs"
              x-large
              :class="{ active: countryMenuValue }"
              color="#FFFFFF"
              v-on="on"
            >
              <!-- <v-icon
                  color="white"
                  large
                >
                  mdi-web
                </v-icon> -->
              <div class="mr-3">
                <v-img
                  v-if="currentCountry === 'US'"
                  max-width="36"
                  src="/img/app-bar/US_flag.png"
                ></v-img>
                <v-img
                  v-if="currentCountry === 'CA'"
                  max-width="36"
                  src="/img/app-bar/canada_flag.png"
                ></v-img>
              </div>
              <span class="currentCountry">
                {{ currentCountry }}
              </span>
              <v-icon right>
                mdi-chevron-down
              </v-icon>
            </v-btn>
          </template>

          <v-list class="countryMenuList">
            <app-bar-item class="px-0">
              <v-list-item @click="setWebsiteLocation('US')">
                <v-list-item-icon>
                  <v-img
                    max-width="36"
                    src="/img/app-bar/US_flag.png"
                  ></v-img>
                </v-list-item-icon>
                <v-list-item-title>
                  US
                </v-list-item-title>
              </v-list-item>
            </app-bar-item>
            <app-bar-item class="px-0">
              <v-list-item @click="setWebsiteLocation('CA')">
                <v-list-item-icon>
                  <v-img
                    max-width="36"
                    src="/img/app-bar/canada_flag.png"
                  ></v-img>
                </v-list-item-icon>
                <v-list-item-title>
                  CA
                </v-list-item-title>
              </v-list-item>
            </app-bar-item>
          </v-list>
        </v-menu>
        <br />
        <v-menu
          v-model="languageMenuValue"
          close-on-click
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="ml-0 pl-0 btn opaque mt-md-1"
              min-width="0"
              text
              v-bind="attrs"
              x-large
              :class="{ active: languageMenuValue, [currentCountry]: true }"
              color="#FFFFFF"
              v-on="on"
            >
              <!-- <v-icon
                  color="white"
                  large
                >
                  mdi-tooltip-text
                </v-icon> -->
              <span class="currentLang text-uppercase">
                {{ currentLang }}
              </span>
              <v-icon right>
                mdi-chevron-down
              </v-icon>
            </v-btn>
          </template>

          <v-list>
            <app-bar-item
              v-for="(languageOption, index) in filteredLanguage"
              :key="index"
              class="px-0"
            >
              <v-list-item @click="setLanguage(languageOption.code)">
                <v-list-item-title>
                  {{ languageOption.label }}
                </v-list-item-title>
              </v-list-item>
            </app-bar-item>
          </v-list>
        </v-menu>
      </div>
      <div class="mb-10" />
    </v-list>
    <notify-user
      :show="showDialog"
      :lang="currentLang"
      :product-available="productAvailable"
      @cancelTrigger="handleCancel"
    >
    </notify-user>
  </v-navigation-drawer>
</template>

<script>
// Utilities
  import { mapState } from 'vuex'
  import Vue from 'vue'
  import ReveChat from '../../../../plugins/revechat'
  import Storage from '../../../../plugins/storage'
  import router from '../../../../router'
  import NotifyUser from '../../../../components/base/NotifyUser'
  import { localize } from 'vee-validate'
  import { loadMenu, checkCaTermPage, isProductAvailable } from '../../../../utils/common'
  import { toCamelCase } from '../../../../utils/normalizeToEnglish'
  export default {
    name: 'BncDrawer',
    components: {
      NotifyUser
    },
    props: {
      expandOnHover: {
        type: Boolean,
        default: false
      }
    },
    data: () => ({
      languageOptions: [
        { code: 'en', label: 'EN' },
        { code: 'fr', label: 'FR' }
      ],
      menuClosed: false,
      countryMenuValue: null,
      languageMenuValue: null,
      showDialog: false,
      currentLang: '',
      currentCountry: 'US',
      productAvailable: false,
      items: [
        // {
        //   icon: 'mdi-hexagon-slice-6',
        //   title: 'US Term',
        //   to: '/afficiencyterm',
        // },
        // {
        //   icon: 'mdi-parachute',
        //   title: 'Canadian Term',
        //   to: '/canadianterm',
        // },
        // {
        //   icon: 'mdi-parachute',
        //   title: 'Canadian Term R&C',
        //   to: '/canadiantermrc',
        // },
        // {
        //   icon: 'mdi-paw',
        //   title: 'Pet',
        //   to: '/pet'
        // },
        // {
        //   icon: 'mdi-car',
        //   title: 'Auto',
        //   to: '/auto',
        // },
        // {
        //   icon: 'mdi-home',
        //   title: 'Homeowners',
        //   to: '/homeowners',
        // },
        {
          icon: 'mdi-key-chain-variant',
          title: 'Renters',
          to: '/renters'
        },
        {
          icon: 'mdi-hand-heart-outline',
          title: 'Term Life',
          to: '/afficiencyterm'
        },
        // {
        //   icon: 'mdi-airplane',
        //   title: 'Canadian Travel',
        //   to: '/Travelca',
        // },
        {
          icon: 'mdi-airplane',
          title: 'Travel',
          to: '/travel/usa'
        }
      // {
      //   group: '/components',
      //   icon: 'mdi-view-comfy',
      //   title: 'components',
      //   children: [
      //     {
      //       title: 'multi',
      //       group: '',
      //       children: [
      //         {
      //           title: 'example',
      //           href: '#',
      //         },
      //       ],
      //     },
      //     {
      //       title: 'buttons',
      //       to: 'buttons',
      //     },
      //     {
      //       title: 'grid',
      //       to: 'grid-system',
      //     },
      //     {
      //       title: 'tabs',
      //       to: 'tabs',
      //     },
      //     {
      //       title: 'notifications',
      //       to: 'notifications',
      //     },
      //     {
      //       title: 'icons',
      //       to: 'icons',
      //     },
      //     {
      //       title: 'typography',
      //       to: 'typography',
      //     },
      //   ],
      // },
      // {
      //   group: '/forms',
      //   icon: 'mdi-clipboard-outline',
      //   title: 'forms',
      //   children: [
      //     {
      //       title: 'rforms',
      //       to: 'regular',
      //     },
      //     {
      //       title: 'eforms',
      //       to: 'extended',
      //     },
      //     {
      //       title: 'vforms',
      //       to: 'validation',
      //     },
      //     {
      //       title: 'wizard',
      //       to: 'wizard',
      //     },
      //   ],
      // },
      // {
      //   group: '/tables',
      //   icon: 'mdi-grid',
      //   title: 'tables',
      //   children: [
      //     {
      //       title: 'rtables',
      //       to: 'regular-tables',
      //     },
      //     {
      //       title: 'etables',
      //       to: 'extended-tables',
      //     },
      //     {
      //       title: 'dtables',
      //       to: 'data-tables',
      //     },
      //   ],
      // },
      // {
      //   group: '/maps',
      //   icon: 'mdi-map-marker',
      //   title: 'maps',
      //   children: [
      //     {
      //       title: 'google',
      //       to: 'google-maps',
      //     },
      //     {
      //       title: 'fullscreen',
      //       to: 'full-screen-map',
      //     },
      //   ],
      // },
      // {
      //   icon: 'mdi-widgets',
      //   title: 'widgets',
      //   to: '/widgets',
      // },
      // {
      //   icon: 'mdi-chart-timeline-variant',
      //   title: 'charts',
      //   to: '/charts',
      // },
      // {
      //   icon: 'mdi-calendar-range',
      //   title: 'calendar',
      //   to: '/calendar',
      // },
      ],
      itemsCA: [
        // {
        //   icon: 'mdi-hexagon-slice-6',
        //   title: 'US Term',
        //   to: '/afficiencyterm',
        // },
        // {
        //   icon: 'mdi-parachute',
        //   title: 'Canadian Term',
        //   to: '/canadianterm',
        // },
        // {
        //   icon: 'mdi-parachute',
        //   title: 'Canadian Term R&C',
        //   to: '/canadiantermrc',
        // },
        // {
        //   icon: 'mdi-paw',
        //   title: 'Pet',
        //   to: '/pet-ca'
        // },
        {
          icon: 'mdi-key-chain-variant',
          title: 'Renters',
          to: '/renters-ca'
        },
        {
          icon: 'mdi-hand-heart-outline',
          title: 'Term Life',
          to: '/canadianterm'
        },
        // {
        //   icon: 'mdi-car',
        //   title: 'Auto',
        //   to: '/auto',
        // },
        // {
        //   icon: 'mdi-home',
        //   title: 'Homeowners',
        //   to: '/homeowners',
        // },
        // {
        //   icon: 'mdi-key-chain-variant',
        //   title: 'Renters',
        //   to: '/renters',
        // },
        // {
        //   icon: 'mdi-hand-heart-outline',
        //   title: 'Term Life',
        //   to: '/termlifehome',
        // },
        {
          icon: 'mdi-airplane',
          title: 'Travel',
          to: '/travel/ca'
        }
      ],
      dashboard: {
        icon: 'mdi-view-dashboard',
        title: 'Dashboard',
        to: '/'
      },
      menuitems: [
        {
          title: 'Digital Products',
          subitems: [
            { title: 'Main', to: '/' },
            { title: 'Auto', to: '/auto' },
            { title: 'US Term Life', to: '/afficiencyterm' },
            { title: 'Homeowners', to: '/homeowners' },
            { title: 'Term Life', to: '/termlifehome' },
            { title: 'Travel CA', to: '/travel/ca' },
            { title: 'Travel US', to: '/travel/usa' },
            // { title: 'Canadian Term', route: '/canadianterm' },
            // { title: 'Canadian Term RC', route: '/canadiantermrc' },
            { title: 'Renters', route: '/renters' },
            // { title: 'Landlord', route: '/landlord' },
            { title: 'Condo', route: '/condo' },
            {
              icon: 'mdi-home',
              title: 'Property and Casualty',
              to: '/coverd-by-blanket'
            }
          // { title: 'Business', route: '/business' },
          ]
        }
        // {
        //   title: 'Covered by Blanket',
        //   subitems: [
        //     {
        //       icon: 'mdi-home',
        //       title: 'Property and Casualty',
        //       to: '/coverd-by-blanket'
        //     }
        //   ]
        // }
      ],
      menuCountryBased: {
        US: [
          // {
          //   icon: 'mdi-paw',
          //   title: 'Pet',
          //   to: '/pet'
          // },
          {
            icon: 'mdi-hand-heart-outline',
            title: 'Term Life',
            to: '/afficiencyterm'
          },
          {
            icon: 'mdi-airplane',
            title: 'Travel',
            to: '/travel/usa'
          },
          {
            icon: 'mdi-key-chain-variant',
            title: 'Renters',
            to: '/renters'
          },
          {
            icon: 'mdi-office-building',
            title: 'Condo',
            to: '/condo'
          }
        ],
        CA: [
          { icon: 'mdi-hand-heart-outline', title: 'Term Life', to: '/termlifehome' },
          { icon: 'mdi-airplane', title: 'Travel US', to: '/travel/usa' },
          { icon: 'mdi-key-chain-variant', title: 'Renters', route: '/renters' },
          { title: 'Covered by Blanke', to: '/bnmdashboard' }
        ]
      }
    }),

    computed: {
      ...mapState(['barColor', 'barImage']),
      drawer: {
        get () {
          console.log('get initial drawer is ', this.$store.state.drawer)
          console.log('viewport state ', this.$store.state.drawer)
          return this.$store.state.drawer
        },
        set (val) {
          console.log('setting drawer is ', val)
          this.$store.commit('SET_DRAWER', val)
        }
      },
      currentCountryComputed () {
        let currentCountry = 'US'
        currentCountry = this.$store.getters.getWebsiteLocation
        return currentCountry
      },
      currentLanguageComputed () {
        return this.$Storage.getCookie('locale') || 'en'
      },
      computedItems () {
        // {
        //   group: '',
        //   icon: 'mdi-hammer',
        //   title: 'Admin',
        //   children: [
        //     {
        //       title: 'Admin Term Life',
        //       to: 'BlanketTermQuestions',
        //     },
        //     {
        //       title: 'Admin Anico Term Life',
        //       to: 'BlanketAnicoPolicyAdmin',
        //     },
        //     {
        //       title: 'Symetra Life App',
        //       to: 'BlanketSymetraQuestions',
        //     },
        //     {
        //       title: 'Anico Life App',
        //       to: 'BlanketAnicoQuestions',
        //     },
        //   ],
        // },
        let menumap = this.items.map(this.mapItem)
        if (this.currentCountryComputed === 'CA') {
          menumap = this.itemsCA.map(this.mapItem)
        }
        let user = this.$store.getters.user
        if (user) {
          if (user.status === 'admin' || user.status === 'tester') {
            let adminMenu = {
              group: '',
              icon: 'mdi-hammer',
              title: 'Admin',
              children: [
                {
                  title: 'Reports',
                  to: 'PlatformReports'
                },
                {
                  title: 'Admin Term Life',
                  to: 'BlanketTermQuestions'
                },
                {
                  title: 'Term Life Question Test',
                  to: 'RepeatTest'
                },
                {
                  title: 'Admin Anico Term Life',
                  to: 'BlanketAnicoPolicyAdmin'
                },
                {
                  title: 'Symetra Life App',
                  to: 'BlanketSymetraQuestions'
                },
                {
                  title: 'Anico Life App',
                  to: 'BlanketAnicoQuestions'
                }
              ]
            }
            menumap.push(adminMenu)
            let reportmenu = {
              group: '',
              icon: 'mdi-chart-bar',
              title: 'Admin Reports',
              children: [
                {
                  to: 'adminreports/BIProfile',
                  title: 'BI Profile'
                },
                {
                  to: 'adminreports/policies',
                  title: 'Admin Policies'
                },
                {
                  to: 'adminreports/salesbypolicy',
                  title: 'Sales By Policy'
                },
                {
                  to: 'adminreports/totalsales',
                  title: 'Total Sales'
                }
              ]
            }
            menumap.push(reportmenu)
          }
        }
        return menumap
      },
      profile () {
        return {
          avatar: true,
          group: '',
          title: this.$t('avatar'),
          children: [
            {
              to: 'profile',
              title: this.$t('menu.profile')
            },
            {
              to: 'userpolicies',
              title: this.$t('menu.myPolicies')
            }, {
              to: 'userapplications',
              title: this.$t('menu.myApplications')
            }
          ]
        }
      },
      aboutUs () {
        return {
          avatar: false,
          group: '',
          title: this.$t('menu.aboutUs'),
          children: [
            {
              to: 'about/team',
              title: this.$t('menu.ourTeam')
            },
            {
              to: 'about/news',
              title: this.$t('menu.news')
            },
            {
              to: 'about/partnerships',
              title: this.$t('menu.partnerships')
            }
          ]
        }
      },
      getProducts (region) {
        return {
          avatar: false,
          group: '',
          title: this.$t('menu.products'),
          children: [
            ...this.menuCountryBased[this.currentCountry]
          ]
        }
      },
      computedCaTermMenu () {
        return loadMenu(this.$Storage.getCookie('locale'))
      },
      filteredLanguage () {
        if (this.currentCountry?.toLowerCase() === 'us') {
          return this.languageOptions.slice(0, -1)
        } else {
          return this.languageOptions
        }
      }
    },

    watch: {
      '$vuetify.breakpoint.xlAndDown' (val) {
        this.$emit('update:expandOnHover', !val)
      }
    },

    mounted () {
      document.addEventListener('click', this.handleDocumentClick)
      this.currentLang = this.currentLanguageComputed
      console.log('this.currentLang : ' + this.currentLang)
      this.currentCountry = this.setDefaultCountry() || 'US'
      this.$store.dispatch('getuserlocation').then(() => {
        const websiteLocation = this.$store.getters.getWebsiteLocation
        this.currentCountry = this.setDefaultCountry() || websiteLocation || 'US'
        if (
          this.$store.getters.getWebsiteLocation === 'CA'
        ) {
          this.countryDialog = true
        }
      })
      this.caTermMenu = this.computedCaTermMenu
      this.menuCountryBased.CA = this.caTermMenu.CA
    },

    methods: {
      toCamelCase,
      redirectToDash () {
        console.log('you clicked')
        return router.push('/')
      },
      navigateToUrl () {
        window.location.href = 'https://insights.blanket.com/'
      },
      redirectToContactUs () {
        const page = this.currentCountry.toLowerCase() === 'us' ? '/contactus' : '/contactus-ca'
        return router.push(page)
      },
      redirectToDownloadApp () {
        return router.push('/download-app')
      },
      setDefaultCountry () {
        return this.$Storage.getCookie('location')
      },
      mapItem (item) {
        // let adminMenu =
        // {
        //   group: '',
        //   icon: 'mdi-hammer',
        //   title: 'Admin',
        //   children: [
        //     {
        //       title: 'Admin Term Life',
        //       to: 'BlanketTermQuestions',
        //     },
        //     {
        //       title: 'Admin Anico Term Life',
        //       to: 'BlanketAnicoPolicyAdmin',
        //     },
        //     {
        //       title: 'Symetra Life App',
        //       to: 'BlanketSymetraQuestions',
        //     },
        //     {
        //       title: 'Anico Life App',
        //       to: 'BlanketAnicoQuestions',
        //     },
        //   ],
        // },
        return {
          ...item,
          children: item.children ? item.children.map(this.mapItem) : undefined,
          title: this.$t(item.title)
        }
      },
      setWebsiteLocation (location, hideModal) {
        let beforeChangeLocaiton = this.currentCountry
        this.$store.commit('setWebsiteLocation', location)
        console.log('DRAWER == this.$store.getters.getWebsiteLocation')
        console.log(this.$store.getters.getWebsiteLocation)
        this.$Storage.setCookie('location', location)
        if (location === 'US') {
          this.currentLang = 'en'
          this.$Storage.setCookie('locale', 'en')
          this.$i18n.locale = this.currentLang
        }
        this.countryDialog = false
        this.currentCountry = this.$store.getters.getWebsiteLocation
        // this.menuitems[0].subitems = this.menuCountryBased[this.currentCountry]
        this.$store.commit('setWebsiteLocation', location)
        if (beforeChangeLocaiton !== location && this.$route.path !== '/') {
          console.log('got to homepage')
          this.$router.push('/')
        }
        if (hideModal && location === 'CA') {
          this.$Storage.setCookie('countryModalDisable', true)
        }
      },
      async setLanguage (languageCode) {
        this.currentLang = languageCode
        const html = document.documentElement // <html> element
        this.$store.commit('setLanguage', languageCode)
        // this.$store.dispatch('updatelang', { languageCode })
        html.setAttribute('lang', languageCode)
        if (checkCaTermPage()) {
          await this.$store.dispatch('updatelang', { languageCode })
          this.showDialog = true
        }
        if (!isProductAvailable() && this.currentLang === 'fr') {
          await this.$store.dispatch('updatelang', { languageCode })
          this.showDialog = true
          this.productAvailable = true
        }
        if (isProductAvailable() && !checkCaTermPage() && this.$i18n.locale !== this.currentLang) {
          await this.$store.dispatch('updatelang', { languageCode })
          this.$Storage.setCookie('locale', this.$store.getters.getLanguage)
          location.reload()
          localize(languageCode)
        }
        document.body.setAttribute('lang', languageCode)
      },
      handleCancel () {
        const updateLang = this.currentLang === 'en' ? 'fr' : 'en'
        this.currentLang = updateLang
        this.$store.commit('setLanguage', updateLang)
        this.showDialog = false
      }
    }
  }
</script>
<style>
/* width */
::-webkit-scrollbar {
  width: 1px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #009db3;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #009db3;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
 .aboutus-style .v-list-item__icon--text{
  display: none;
  }
  .style-title{
    line-height:22px;
  }
  .aboutus-style{
    padding: 0px;
  }
  .aboutus-style, .container-covered{
    padding-left: 0px!important;
  }
  .v-list-item__title, .v-list-item__subtitle {
    white-space: normal;
  }
  #icon-container3CA{
    display: none;
  }
</style>

<style lang="sass">
@import '~vuetify/src/styles/tools/_rtl.sass'

#core-navigation-drawer
  &.v-navigation-drawer--mini-variant
    .v-list-item
      justify-content: flex-start !important

    .v-list-group--sub-group
      display: block !important

  .v-list-group__header.v-list-item--active:before
    opacity: .24

  .v-list-item
    &__icon--text,
    &__icon:first-child
      justify-content: center
      text-align: center
      width: 20px

      +ltr()
        margin-right: 24px
        margin-left: 12px !important

      +rtl()
        margin-left: 24px
        margin-right: 12px !important

  .v-list--dense
    .v-list-item
      &__icon--text,
      &__icon:first-child
        margin-top: 10px

  .v-list-group--sub-group
    .v-list-item
      +ltr()
        padding-left: 8px

      +rtl()
        padding-right: 8px

    .v-list-group__header
      +ltr()
        padding-right: 0

      +rtl()
        padding-right: 0

      .v-list-item__icon--text
        margin-top: 19px
        order: 0

      .v-list-group__header__prepend-icon
        order: 2

        +ltr()
          margin-right: 8px

        +rtl()
          margin-left: 8px
</style>

<style scoped>
  .CA {
    display: block;
  }
  .US {
    display: none;
  }
.main-menu-text{
  position: relative;
  left: -10px;
  top: 5px;
}
::v-deep .product-menu>.v-list-item--active::before{
  background: rgb(255, 255, 255)!important;
}
::v-deep .v-list .v-list-item--active .v-icon {
  color: #fff;
}
::v-deep .product-menu.v-list-group--active.primary--text {
  color: #fff!important;
  caret-color: #fff!important;
}
.download-app::before {
  content: '';
  display: inline-block;
  width: 35px;
  height: 35px;
  background-image: url('/img/mobile_download.svg');
  background-size: contain;
  background-repeat: no-repeat;
  vertical-align: middle;
}
.download-app{
  position: relative;
  left: -8px;
  cursor: pointer;
  text-indent: -40px;
  padding-left: 40px;
}
.pointer-style {
  cursor: pointer;
}
::v-deep .v-list--nav .v-list-item:not(:last-child):not(:only-child), .v-list--rounded .v-list-item:not(:last-child):not(:only-child) {
  margin-bottom: 0px!important;
}
::v-deep .top-content .v-list-item__title {
  line-height: 18px;
}
::v-deep .v-list-group__items .v-list-item {
  margin-bottom: 0px!important;
}
::v-deep .mdi-chevron-down::before {
    content: "\F035D"!important;
}
::v-deep .v-navigation-drawer .v-icon.v-icon {
  font-size: 19px;
}
.v-navigation-drawer .v-icon.v-icon {
  font-size: 19px!important;
}
.main-menu-text .v-list-item__title {
  position: relative;
  top: -5px;
}
</style>
